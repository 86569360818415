<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.cabinets") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>

                <th v-if="columns.url.show">
                    {{ columns.url.title }}
                </th>

                <th v-if="columns.register_rule_id.show">
                    {{ columns.register_rule_id.title }}
                </th>

                <th v-if="columns.show_referral.show">
                    {{ columns.show_referral.title }}
                </th>

                <th v-if="columns.countries.show">
                    {{ columns.countries.title }}
                </th>

                <th v-if="columns.filials.show">
                    {{ columns.filials.title }}
                </th>

                <th v-if="columns.tariffs.show">
                    {{ columns.tariffs.title }}
                </th>

                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.name.show">
                  <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                  ></crm-input>
              </th>

              <th v-if="columns.url.show">
                  <crm-input
                      :placeholder="columns.url.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.url"
                  ></crm-input>
              </th>

              <th v-if="columns.register_rule_id.show">
              </th>

              <th v-if="columns.show_referral.show">
              </th>

              <th v-if="columns.countries.show"></th>

              <th v-if="columns.filials.show"></th>

              <th v-if="columns.tariffs.show"></th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="cabinet in list" :key="cabinet.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ cabinet.id }}
                </td>

                <td v-if="columns.name.show">
                    {{ cabinet.name }}
                </td>

                <td v-if="columns.url.show">
                    {{ cabinet.url }}
                </td>

                <td v-if="columns.register_rule_id.show">
                    {{ cabinet.registerRule ? cabinet.registerRule.name[sys_lang] : '' }}
                </td>

                <td v-if="columns.show_referral.show">
                    {{ cabinet.show_referral ? $t('message.show') : $t('message.hide') }}
                </td>

                <td v-if="columns.countries.show">
                    <span v-if="cabinet.countries && cabinet.countries.length > 0">
                        <p v-for="(country, index) in cabinet.countries" :key="'fil_'+index">
                            {{index+1}}. {{ country.name }}
                        </p>
                    </span>
                </td>

                <td v-if="columns.filials.show">
                    <span v-if="cabinet.filials && cabinet.filials.length > 0">
                        <p v-for="(filial, index) in cabinet.filials" :key="'fil_'+index">
                            {{index+1}}. {{ filial.name }}
                        </p>
                    </span>
                </td>

                <td v-if="columns.tariffs.show">
                    <span v-if="cabinet.tariffs && cabinet.tariffs.length > 0">
                        <p v-for="(tariff, index) in cabinet.tariffs" :key="'tar_'+index">
                            {{index+1}}. {{ tariff.name }}
                        </p>
                    </span>
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ cabinet.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ cabinet.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                    <crm-settings
                        :name="$options.name"
                        :model="cabinet"
                        :actions="actions"
                        :permissionShow="'cabinets.update'"
                        :permissionDestroy="'cabinets.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"  
                :visible.sync="drawerCreate" 
                ref="drawerCreate"
                size="80%"
                class="body_scroll_80 bg-se"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="80%"
                class="body_scroll_80 bg-se"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
    name: "cabinets",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "cabinets/list",
            columns: "cabinets/columns",
            pagination: "cabinets/pagination",            
            filter: "cabinets/filter",
            sort: "cabinets/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
        sys_lang() {
          return localStorage.getItem('sys_language') ? localStorage.getItem('sys_language') : 'ru';;
        }
    },
    methods: {
        ...mapActions({
            updateList: "cabinets/index",
            setPagination: "cabinets/setPagination",
            updateSort: "cabinets/updateSort",
            updateFilter: "cabinets/updateFilter",
            updateColumn: "cabinets/updateColumn",
            updatePagination: "cabinets/updatePagination",
            show: "cabinets/show",
            empty: "cabinets/empty",
            delete: "cabinets/destroy",
            refreshData: "cabinets/refreshData",
        }),
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('cabinets/EMPTY_LIST');
        next()
    },
};
</script>

