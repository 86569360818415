<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{ $t("message.new_m", { m: $t("message.cabinet") }) }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item prop="name" :label="$t('message.name')">
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="12">
                      <el-form-item prop="url" :label="$t('message.url')">
                        <el-input
                            :placeholder="$t('message.url')"
                            v-model="form.url"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="12">
                      <el-form-item prop="register_rule_id" :label="$t('message.registerRule')">
                        <select-register-rule
                          :collapse_tags="false"
                          :multiple="true"
                          :size="'medium'"
                          :placeholder="columns.register_rule_id.title"
                          :id="form.register_rule_id"
                          v-model="form.register_rule_id"
                        >
                        </select-register-rule>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="12">
                      <el-form-item prop="show_referral" :label="$t('message.show_referral')">
                        <el-checkbox class="w-100" v-model="form.show_referral" :label="$t('message.show_referral')" size="medium" border></el-checkbox>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                      <el-form-item prop="countries" :label="$t('message.countries')">
                        <select-country
                          :size="'medium'"
                          :collapse_tags="false"
                          :multiple="true"
                          :placeholder="columns.countries.title"
                          :id="form.countries"
                          v-model="form.countries"
                        >
                        </select-country>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                      <el-form-item prop="filials" :label="$t('message.filials')">
                        <select-filial
                          v-model="form.filials"
                          :filial_ids="form.filials"
                          :size="'medium'"
                          :placeholder="$t('message.filials')"
                        >
                        </select-filial>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                      <el-form-item prop="tariffs" :label="$t('message.directionTariff')">
                        <select-tariff
                            ref="Tariff"
                            :size="'medium'"
                            :show_all="true"
                            :multiple="true"
                            :placeholder="$t('message.directionTariff')"
                            :id="form.tariffs"  
                            v-model="form.tariffs"
                        >
                        </select-tariff>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="12">
                      <el-form-item :label="$t('message.dark_plugin')" class="label_mini" prop="dark_plugin">
                          <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            action="/"
                            :on-change="file => updateImageList(file, 'dark_plugin')"
                            list-type="picture-card"
                            accept="image/jpeg, image/png"
                            :auto-upload="false"
                          >
                            <img v-if="form.dark_plugin" :src="form.dark_plugin" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                      </el-form-item>
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="12">
                      <el-form-item :label="$t('message.light_plugin')" class="label_mini" prop="light_plugin">
                          <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            action="/"
                            :on-change="file => updateImageList(file, 'light_plugin')"
                            list-type="picture-card"
                            accept="image/jpeg, image/png"
                            :auto-upload="false"
                          >
                            <img v-if="form.light_plugin" :src="form.light_plugin" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                          </el-upload>
                      </el-form-item>
                    </el-col>
                    <!-- end-col -->

                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import SelectFilial from "@/components/multiSelects/multi-select-filials";
import SelectTariff from '@/components/selects/select-direction-tariff';
import selectCountry from "@/components/inventory/select-country";
import selectRegisterRule from "@/components/inventory/select-register-rule";

export default {
  components: {
      SelectTariff,
      SelectFilial,
      selectCountry,
      selectRegisterRule
  },
  mixins: [form, drawer],
  data() {
    return {
      updateImage: {
        dark_plugin: [],
        light_plugin: [],
      },
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "cabinets/rules",
          model: "cabinets/model",
          columns: "cabinets/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "cabinets/store",
      }),
      updateImageList(file, key) {
        if(file.size < 5200000){
          this.form[key] = URL.createObjectURL(file.raw);
          this.updateImage[key] = file.raw;
        }else{
          this.$message({
              type: "warning",
              message: this.$t('message.file_size_limit_warning', {number: 5})
            });
        }
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                this.loadingButton = true;
                let formData = new FormData();

                for (const key in this.form) {
                  if (key != "dark_plugin", key != "light_plugin") {
                    if (this.form[key] && !Array.isArray(this.form[key])) {
                      formData.append(key, this.form[key]);
                    } else if (Array.isArray(this.form[key])) {
                      this.form[key].forEach((id, index) => {
                          formData.append(`${key}[${index}]`, id);
                      });
                    } else {
                      formData.append(key, "");
                    }
                  }
                }

                if (_.size(this.updateImage) > 0) {
                  for (const key in this.updateImage) {
                    if (this.updateImage.hasOwnProperty(key)) {
                      const element = this.updateImage[key];
                      formData.append(key, element);
                    }
                  }
                }

                this.save(formData)
                    .then((res) => {
                        this.loadingButton = false;
                        this.$alert(res);
                        this.parent().listChanged();
                        if (close) this.close();
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
              }
          });
      },
  },
};
</script>
