<template>
    <div>
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.registerRules')" filterable clearable
        :size="size" class="w-100">
        <el-option v-for="(registerRule, index) in registerRules" :key="'registerRules-' + index" :label="registerRule.name[sys_lang]" :value="registerRule.id"></el-option>
      </el-select>
    </div>
  </template>
  <script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id
          },
          immediate: true
        }
      },
      data() {
        return {
          selected: null,
        }
      },
      mounted() {
        if (this.registerRules && this.registerRules.length === 0) this.updateInventory();
      },
      computed: {
        ...mapGetters({
          registerRules: 'registerRules/inventory'
        }),
        sys_lang() {
          return localStorage.getItem('sys_language') ? localStorage.getItem('sys_language') : 'ru';;
        }
      },
      methods: {
        ...mapActions({
          updateInventory: 'registerRules/inventory'
        }),
        dispatch(e) {
          this.$emit('input', e)
          this.selected = e
        }
      },
    }
  
  </script>
  